export default {
    ADD_CATEGORY(state, item) {
      state.categories.unshift(item)
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    // SET_LABELS(state, labels) {
    //   state.eventLabels = labels
    // },
    UPDATE_CATEGORY(state, category) {
        const categoryIndex = state.categories.findIndex((p) => p.id == category.id)
        Object.assign(state.categories[categoryIndex], category)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.categories.findIndex((p) => p.id == itemId)
        state.categories.splice(ItemIndex, 1)
    },
  }
  