import { render, staticRenderFns } from "./DataViewSidebarProducts.vue?vue&type=template&id=11e0614b&scoped=true"
import script from "./DataViewSidebarProducts.vue?vue&type=script&lang=js"
export * from "./DataViewSidebarProducts.vue?vue&type=script&lang=js"
import style0 from "./DataViewSidebarProducts.vue?vue&type=style&index=0&id=11e0614b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11e0614b",
  null
  
)

export default component.exports