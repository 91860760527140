import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("/api/data-list/categories/", {item: item})
        .then((response) => {
          commit('ADD_CATEGORY', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // fetchDataListItems({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios.get("http://www.mocky.io/v2/5ed869a63100006000c4e45b")
  //       .then((response) => {
  //         commit('SET_CATEGORIES', response.data.categories)    //response.data []
  //         resolve(response.data.categories)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
    // return new Promise((resolve, reject) => {
    //   axios.get("/api/data-list-categories/categories")
    //     .then((response) => {
    //       console.log(response)
    //       commit('SET_CATEGORIES', response.data)    //response.data []
    //       resolve(response)
    //     })
    //     .catch((error) => { reject(error) })
    // })
  // },
  // fetchEventLabels({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios.get("/api/apps/calendar/labels")
  //       .then((response) => {
  //         commit('SET_LABELS', response.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  getCategories({ commit }, idInst) {
    return new Promise((resolve, reject) => {
      let endpoint = idInst != null ? "/" + idInst : "";
      axios.get(`pos/categories${endpoint}`)
      .then((response) => {
        commit('SET_CATEGORIES', response.data.data)
        return resolve(response.data)
      })
      .catch(error => {reject(error)})
    })
  },
  addCategory({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put("pos/categories", item)
        .then((response) => {
          if (response.status) {
            // commit('ADD_CATEGORY', Object.assign(item, {id: response.data.id}))
            commit('ADD_CATEGORY', response.data.data)
            resolve(response)
          } else {
            reject(response.msg)
          }
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCategory({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post(`pos/categories/${item.id}`, item)
        .then((response) => {
          commit('UPDATE_CATEGORY', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateItem({ commit }, item) {
    commit('UPDATE_CATEGORY', item)
    // return new Promise((resolve, reject) => {
    //   axios.post(`/api/data-list/categories/${item.id}`, {item: item})
    //     .then((response) => {
    //       commit('UPDATE_CATEGORY', item)
    //       resolve(response)
    //     })
    //     .catch((error) => { reject(error) })
    // })
  },
  removeItem({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/data-list/categories/${itemId}`)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // eventDragged({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.post(`/api/apps/calendar/event/dragged/${payload.event.id}`, {payload: payload})
  //       .then((response) => {

  //         // Convert Date String to Date Object
  //         let event = response.data
  //         event.startDate = new Date(event.startDate)
  //         event.endDate = new Date(event.endDate)

  //         commit('UPDATE_EVENT', event)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
}
