<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "Agregar nuevo" : "Actualizar" }} producto</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6" v-show="!this.addStock">

        <!-- Product Image -->
        <template v-if="dataImg">

          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="dataImg" alt="img" class="responsive">
            <span class="text-danger text-sm" v-show="errors.has('product_image')">{{ errors.first('product_image') }}</span>
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*" name="product_image">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Actualizar imágen</vs-button>
            <!-- <vs-button type="flat" color="#999" @click="dataImg = null">Eliminar imágen</vs-button> -->
          </div>
          <!-- <div v-show="image_error" class="vs-alert con-icon"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">new_releases</i><span class="text-danger text-sm">{{ image_error }}</span></div> -->
          <vs-alert v-show="image_error" color="danger" style="height: initial" icon-pack="feather" icon="icon-info">
            {{ image_error }}
          </vs-alert>
        </template>

        <!-- NAME -->
        <vs-input label="Nombre" v-model="dataName" class="mt-5 w-full" name="item-name" v-validate="'required|max:50'" data-vv-as="Nombre"/>
        <span class="text-danger text-sm" v-show="errors.has('item-name')">{{ errors.first('item-name') }}</span>

        <!-- CATEGORY -->
        <div class="mt-5">
          <label class="vs-input--label">Categoría</label>
          <v-select browserAutocomplete="off" autocomplete="off" v-model="dataCategory" :reduce="dataCategory => dataCategory.value" :options="category_choices" :clearable="false" v-validate="'required'" name="item-category" :dir="$vs.rtl ? 'rtl' : 'ltr'" data-vv-as="Categoría"/>
          <span class="text-danger text-sm" v-show="errors.has('item-category')">{{ errors.first('item-category') }}</span>
        </div>
        <!-- <vs-select v-model="dataCategory" label="Categoría" class="mt-5 w-full" name="item-category" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in category_choices" />
        </vs-select> -->

        <!-- ORDER STATUS -->
        <div class="mt-5">
          <label class="vs-input--label">Estado</label>
          <v-select browserAutocomplete="off" autocomplete="off" v-model="dataStatus" :reduce="dataStatus => dataStatus.value" :options="order_status_choices" :clearable="false" v-validate="'required'" name="item-status" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <!-- <span class="text-danger text-sm" v-show="errors.has('item-category')">{{ errors.first('item-category') }}</span> -->
        </div>
        <!-- <vs-select v-show="false" v-model="dataOrder_status" label="Estado" class="mt-5 w-full">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in order_status_choices" />
        </vs-select> -->

        <!-- PRICE -->
        <vs-input
          icon-pack="feather"
          icon="icon-dollar-sign"
          label="Precio"
          v-model="dataPrice"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 99999 }"
          name="item-price"
          data-vv-as="Precio"
          />
        <span class="text-danger text-sm" v-show="errors.has('item-price')">{{ errors.first('item-price') }}</span>

         <!-- COST -->
        <vs-input
          icon-pack="feather"
          icon="icon-dollar-sign"
          label="Costo"
          v-model="dataCost"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 99999 }"
          name="item-cost"
          data-vv-as="Costo"
          />
        <span class="text-danger text-sm" v-show="errors.has('item-cost')">{{ errors.first('item-cost') }}</span>

        <!-- STOCK -->
        <vs-input
          icon-pack="feather"
          icon="icon-package"
          label="Stock Inicial"
          v-show="!dataId"
          v-model="dataStock"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /\d+(\.\d+)?$/, max_value: 99999 }"
          name="item-stock"
          data-vv-as="Stock Inicial"/>
        <span class="text-danger text-sm" v-show="errors.has('item-stock')">{{ errors.first('item-stock') }}</span>

        <!-- STOCK MIN-->
        <vs-input
          icon-pack="feather"
          icon="icon-package"
          label="Stock Mínimo"
          v-model="dataStock_min"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /\d+(\.\d+)?$/, max_value: 99999 }"
          name="item-stock-min"
          data-vv-as="Stock Mínimo"/>
        <span class="text-danger text-sm" v-show="errors.has('item-stock-min')">{{ errors.first('item-stock-min') }}</span>

        <!-- INGREDIENTS -->

        <template>
          <div class="vs-component vs-con-input-label vs-input mt-5 w-full vs-input-primary">
            <label for="item-ingredients" class="vs-select--label">Contiene</label>
            <v-select
              v-model="dataIngredients"
              icon-pack="feather"
              icon="icon-package"
              multiple
              :closeOnSelect="false"
              :options="ingredients_choices"
              class="w-full"
              name="item-ingredients"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              data-vv-as="Ingredientes"/>
          </div>
        </template>

        <!-- Upload -->
        <!-- <vs-upload text="Upload Image" class="img-upload" ref="fileUpload" /> -->

        <div class="upload-img mt-5" v-if="!dataImg">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-alert v-show="image_error" color="danger" class="mt-5 mb-5" icon-pack="feather" icon="icon-info" style="height:initial;min-height:62px">{{ image_error }}</vs-alert>
          <vs-button @click="$refs.uploadImgInput.click()">Subir imágen</vs-button>
          <!-- <div v-show="image_error" class="vs-alert con-icon"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">new_releases</i><span class="text-danger text-sm">{{ image_error }}</span></div> -->
        </div>
      </div>
      <div class="p-6" v-if="this.addStock">
        <!-- NAME -->
        <vs-input label="Nombre" disabled="disabled" v-model="dataName" class="mt-5 w-full" name="item-name" v-validate="'required'" data-vv-as="Nombre"/>
         <!-- STOCK -->
        <vs-input
          icon-pack="feather"
          icon="icon-package"
          label="Stock Actual"
          disabled="disabled"
          v-model="dataStock"
          class="mt-5 w-full"
          data-vv-as="Stock Actual"/>


        <div class="mt-5">
          <label for="modifyStock">Agregar o Quitar Stock</label>
          <p><span>Utilice números <strong>positivos para agregar</strong> productos o números <strong>negativos para bajar</strong> productos del stock.</span></p>
          <vs-input-number
            name="item-stock-modify"
            min="-2500"
            max="2500"
            v-model="modifyStock"
            v-validate="{ required: true, regex: /\d+(\.\d+)?$/ }"
            />
            <span class="text-danger text-sm" v-show="errors.has('item-stock-modify')">{{ errors.first('item-stock-modify') }}</span>
          <!-- Motivo -->
          <vs-input label="Motivo" v-model="obs" class="mt-5 w-full" name="item-motivo" v-validate="'max:100'"/>
          <span class="text-danger text-sm" v-show="errors.has('item-motivo')">{{ errors.first('item-motivo') }}</span>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || loading" id="saveButton">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moduleDataListCategories from '@/store/data-list-categories/moduleDataListCategories.js'
import moduleDataListIngredients from '@/store/data-list-ingredients/moduleDataList.js'
import vSelect from 'vue-select'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
    addStock: {
      type: Boolean,
      required: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { id, image, name, price, cost, stock, stock_min, id_category, ingredients, status } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataCategory = id_category
        this.dataImg = this.$baseUrlImg + image
        this.dataName = name
        this.dataPrice = price
        this.dataCost = cost
        this.dataStock = stock
        this.dataStock_min = stock_min
        this.image_loaded = image ? true : false
        this.image_error = ''
        this.modifyStock = 0,
        this.obs = '',
        this.dataCategoryId = id_category,
        this.dataIngredients = [],
        this.dataStatus = status
        this.initValues()

        if(ingredients && ingredients.length > 0) {
          ingredients = Array.from(ingredients.replace("[","").replace("]","").split(","));
          //this.dataIngredients = [{label: "test",value:1}];
          ingredients.forEach(e => {
            let ing = this.ingredients_choices.find(ing => ing.value == parseInt(e));
            if (ing !== undefined)
              this.dataIngredients.push({label: ing.label, value: e});
          })
        }
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {

      dataId: null,
      dataName: "",
      dataCategory: null,
      dataImg: null,
      dataPrice: 0,
      dataCost: 0,
      dataStock: 0,
      dataStock_min: 0,
      dataCategoryId: 0,
      dataIngredients: [],
      dataStatus: 0,

      modifyStock: 0,
      obs: "",

      product_image: {
        size: '',
        width: '',
        height: ''
      },

      image_loaded: false,
      image_error: '',

      ingredients_choices: [],
      category_choices: [],

      selected: [],

      order_status_choices: [
        {label: 'Activo', value: 1 },
        {label: 'Inactivo', value: 0 }
      ],
      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
      loading: false
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
      if (this.addStock) {
        return !this.errors.any() && this.modifyStock != 0 && (this.modifyStock < 0 ? this.obs : true )
      } else {
        return !this.errors.any() && this.dataName && this.dataCategory && (this.dataPrice >= 0) && (this.dataCost >= 0) && this.image_loaded && this.image_error == ''
      }

    },

    //console: () => console,
  },
  methods: {
    initValues() {
      if(this.data.id) return
        this.dataId = null
        this.dataName = ""
        this.dataCategory = null
        this.dataPrice = 0
        this.dataCost = 0
        this.dataStock = 0
        this.dataStock_min = 2
        this.dataImg = null
        this.image_loaded = false
        this.image_error = ''
        this.modifyStock = 0
        this.obs = ''
        this.dataCategoryId = 0
        this.dataIngredients = []
        this.dataStatus = 1
    },
    submitData() {
      if (this.addStock) {
        this.updateStock()
      } else {
        this.$validator.validateAll().then(result => {
          this.disableSaveButton();
          if (result) {
            let ing = []

            if (this.dataIngredients) {
              this.dataIngredients.forEach(e => {
                ing.push(e.value)
              })
            }

            const obj = {
              id: this.dataId,
              id_institution: this.$store.state.AppActiveUser.institution.id,
              name: this.dataName,
              image: (this.image_loaded) ? this.dataImg : '',
              id_category: this.dataCategory,
              description: '',
              price: this.dataPrice,
              cost: this.dataCost,
              stock: this.dataStock,
              stock_min: this.dataStock_min,
              obs: '',
              ingredients: ing,
              status: this.dataStatus
            }

            if(this.dataId !== null && this.dataId >= 0) {
              this.$store.dispatch("dataList/updateProduct", obj)
                .then(response => {
                  this.$emit("showNotify",response.data.status)
                  if(response.data.status) { this.closeSidebar() }
                  this.enableSaveButton()
                })
                .catch(err => {
                  this.$emit("showNotify",false)
                  this.enableSaveButton();
                  console.error(err);
                })
            } else {
              delete obj.id
              delete obj.status
              this.$store.dispatch("dataList/addProduct", obj)
              .then(response => {
                this.$emit("showNotify",response.data.status)
                if(response.data.status) { this.closeSidebar() }
                this.enableSaveButton()
              })
              .catch(err => {
                this.$emit("showNotify",false)
                this.enableSaveButton();
                console.error(err);
              })
            }
          }
        })
      }

    },
    closeSidebar() {
      this.$emit('closeSidebar')
      this.initValues()
    },
    disableSaveButton() {
      this.loading = true
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#saveButton",
        scale: 0.45
      })
    },
    enableSaveButton() {
      this.loading = false
      this.$vs.loading.close("#saveButton > .con-vs-loading")
    },
    updateCurrImg(input) {
      this.image_loaded = false;
      this.image_error = '';
      if (input.target.files && input.target.files[0]) {
        this.product_image.size = input.target.files[0].size;
        var reader = new FileReader()
        reader.onload = e => {
          let img = new Image();
          img.onload = () => {
            this.product_image.width = img.width;
            this.product_image.height = img.height;
            if (this.product_image.width != this.product_image.height || this.product_image.width > 300 || this.product_image.width < 120 ) {
              this.image_error = 'La imágen debe ser cuadrada (misma altura y ancho) con un máximo de 300px y un mínimo de 120px';
            } else {
              if (this.product_image.size > 100000){
                this.image_error = 'La imágen excede el tamaño máximo (100 KB).';
              } else {
                this.dataImg = e.target.result
                this.image_loaded = true;
              }
            }
          }
          img.src = e.target.result;
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    updateStock() {
      if (this.modifyStock != 0) {
        const obj = {
          id: this.dataId,
          obs: this.obs,
          stock: Number(this.modifyStock)
        }
        this.disableSaveButton();
        this.$store.dispatch("dataList/addStock", obj)
        .then(response => {
          this.$emit("showNotify",response.data.status)
          this.enableSaveButton();
        })
        .catch(err => {
          this.$emit("showNotify",false);
          this.enableSaveButton();
          console.error(err);
        })

        this.$emit('closeSidebar')
        this.initValues()
      }

    },
  },
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
  },
  created() {
    if(!moduleDataListCategories.isRegistered) {
      this.$store.registerModule('dataListCategories', moduleDataListCategories)
      moduleDataListCategories.isRegistered = true
    }
    // this.$store.dispatch("dataList/fetchDataListItems")

    this.$store.dispatch("dataListCategories/getCategories", null)
    .then(response => {
      response.data.forEach(element => {
        if(element.status == 1)
          this.category_choices.push({label: element.name, value: element.id})
      });
    })
    .catch(error => {console.log(error)})

    //Ingredients
    if(!moduleDataListIngredients.isRegistered) {
      this.$store.registerModule('dataListIngredients', moduleDataListIngredients)
      moduleDataListIngredients.isRegistered = true
    }

    this.$store.dispatch('dataListIngredients/getIngredients')
      .then(response => {
        this.ingredients = response.data
        response.data.forEach(e => {
          this.ingredients_choices.push({label: e.name, value: e.id})
        })
      })
      .catch(error => console.log(error))

  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
