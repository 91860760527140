import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("/api/data-list/products/", {item: item})
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addProduct({ commit, rootGetters }, item) {
    return new Promise((resolve, reject) => {
      axios.put("pos/products", item)
        .then((response) => {
          if(!response.data.status)
            reject(response.data.msg)

          const categories = rootGetters['dataListCategories/getCategories']
          let cat = categories.find(category => category.id == item.id_category)
          response.data.data.category = cat ? cat.name : ""
          // item.img = response.data.data.image
          // commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
          commit('ADD_ITEM', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getProducts({ commit, dispatch, rootGetters }, idInstitute) {
    return new Promise((resolve, reject) => {
      let endpoint = idInstitute != null ? "/" + idInstitute : "";
      axios.get(`pos/products${endpoint}`)
        .then((response) => {
          return new Promise((resolve) => {
            if(rootGetters['dataListCategories/getCategories'] === undefined || (rootGetters['dataListCategories/getCategories'] && rootGetters['dataListCategories/getCategories'].length == 0)) {
              return dispatch('dataListCategories/getCategories', null, {root: true})
                .then(() => {
                  return resolve(response)
                })
            } else {
              return resolve(response)
            }
          })
        })
        .then(response => {
          const categories = rootGetters['dataListCategories/getCategories']
          response.data.data.forEach(element => {
            var cat_element = categories.find((category) => category.id == element.id_category)
            element.category = cat_element ? cat_element.name : ""
          });
          commit('SET_PRODUCTS', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getProductsIo({commit}, idInstitute) {
    return new Promise((resolve,reject) => {
      axios.get(`pos/products/io/${idInstitute}`)
      .then(response => {
        if(response.status) {
          commit('SET_PRODUCTS_IO', response.data.data)
        }
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  addStock({ commit }, payload) {
    const {id, ...body} = payload
    return new Promise((resolve, reject) => {
      axios.post(`pos/stock/${id}`, {...body})
        .then((response) => {

          if(!response.data.status) {
            reject(response.data.msg)
          }

          commit('UPDATE_PRODUCT', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateProduct({ commit, rootGetters }, item) {
    return new Promise((resolve, reject) => {
      axios.post(`pos/products/${item.id}`, item)
        .then((response) => {
          if(response.data.status) {
            const categories = rootGetters['dataListCategories/getCategories']
            response.data.data.category = categories.find((category) => category.id == response.data.data.id_category).name
            commit('UPDATE_PRODUCT', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchDataListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/data-list/products")
        .then((response) => {
          commit('SET_PRODUCTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // fetchEventLabels({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios.get("/api/apps/calendar/labels")
  //       .then((response) => {
  //         commit('SET_LABELS', response.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/data-list/products/${item.id}`, {item: item})
        .then((response) => {
          commit('UPDATE_PRODUCT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeItem({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/data-list/products/${itemId}`)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // eventDragged({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.post(`/api/apps/calendar/event/dragged/${payload.event.id}`, {payload: payload})
  //       .then((response) => {

  //         // Convert Date String to Date Object
  //         let event = response.data
  //         event.startDate = new Date(event.startDate)
  //         event.endDate = new Date(event.endDate)

  //         commit('UPDATE_EVENT', event)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
}
