import axios from "@/axios.js"

export default {
  addIngredient({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put("pos/ingredients", item)
        .then((response) => {
            if (response.data.status)
                commit('ADD_ITEM', response.data.data)
          
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  getIngredients({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`pos/ingredients`)
        .then((response) => {
          commit('SET_INGREDIENTS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateIngredient({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post(`pos/ingredients/${item.id}`, item)
        .then((response) => {
          if(response.data.status) {
            commit('UPDATE_INGREDIENT', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}