<template>
  <div id="data-list-thumb-view" class="data-list-container">

    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" :addStock="addingStock" @showNotify="showNotify"/>

    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="products">
      <!-- multiple -->

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center">

          <!-- ACTION - DROPDOWN -->
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">

            <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Eliminar</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Archive</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Print</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Another Action</span>
                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown> -->

          <!-- ADD NEW -->
          <!-- <div class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Agregar nuevo</span>
          </div> -->
          <vs-button class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary" color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="addNewData">Agregar Nuevo</vs-button>
        </div>


        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ products.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : products.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=50">
              <span>50</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th>Imagen</vs-th>
        <vs-th sort-key="name">Nombre</vs-th>
        <vs-th sort-key="category">Categoría</vs-th>
        <vs-th sort-key="popularity">Stock</vs-th>
        <vs-th sort-key="stock_min">Stock Mín.</vs-th>
        <vs-th sort-key="order_status">Estado</vs-th>
        <vs-th sort-key="price">Precio</vs-th>
        <vs-th sort-key="cost">Costo</vs-th>
        <vs-th>Acción</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <vs-td class="img-container">
              <img :src="imgPath + tr.image" class="product-img" />
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.category }}</p>
            </vs-td>

            <vs-td>
              <!-- <vx-tooltip :text="Number(tr.stock)"> -->
                <span>{{tr.stock}}</span>
                <vs-progress :percent="(Number(tr.stock) > 0) ? Number(tr.stock) : 0" :color="getPopularityColor(Number(tr.stock))" class="shadow-md" />
              <!-- </vx-tooltip> -->
            </vs-td>

            <vs-td>
              <p class="product-stock-min">{{tr.stock_min}}</p>
            </vs-td>

            <vs-td>
              <vs-chip :color="getStatusColor(tr.status)" class="product-order-status">{{ getStatusText(tr.status) }}</vs-chip>
            </vs-td>

            <vs-td>
              <p class="product-price">${{ tr.price }}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">${{ tr.cost }}</p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <vx-tooltip text="Editar" class="float-left">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
              </vx-tooltip>
              <vx-tooltip :text="tr.status == 1 ? 'Eliminar' : 'Activar'" class="float-left">
                <feather-icon :icon="tr.status == 1 ? 'TrashIcon' : 'RefreshCwIcon'" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr)" />
              </vx-tooltip>
              <vx-tooltip text="Modificar Stock" class="float-left">
                <feather-icon icon="PlusIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="addStock(tr)" />
              </vx-tooltip>
            </vs-td>

          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import DataViewSidebar from './Datalist/DataViewSidebarProducts.vue'
import moduleDataList from "@/store/data-list/moduleDataList.js"
import moduleDataListCategories from '@/store/data-list-categories/moduleDataListCategories.js'

export default {
  components: {
    DataViewSidebar
  },
  data() {
    return {
      selected: [],
      products: [],
      itemsPerPage: 10,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {},
      addingStock: false,
      imgPath: this.$baseUrlImg
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    // products() {
    //   return this.$store.state.dataList.products
    // },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length
    }
  },
  methods: {
    addNewData() {
      this.sidebarData = {}
      this.addingStock =  false
      this.toggleDataSidebar(true)
    },
    deleteData(row) {
      //this.$store.dispatch("dataList/removeItem", id).catch(err => { console.error(err) })
      const obj = {
        id: row.id,
        id_institution: row.id_institution,
        id_category: row.id_category,
        name: row.name,
        description: row.description | "",
        price: row.price,
        image: row.image,
        cost: row.cost,
        status: (row.status == 1) ? 0 : 1,
        ingredients: JSON.parse(row.ingredients),
        stock: row.stock,
        stock_min: row.stock_min
      }

      this.$store.dispatch("dataList/updateProduct", obj)
      .then(response => {
        this.showNotify(response.data.status)
      })
      .catch(err => { console.error(err) })
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data
      this.addingStock =  false
      this.toggleDataSidebar(true)
    },
    addStock(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data
      this.addingStock =  true
      this.toggleDataSidebar(true)
    },
    // getOrderStatusColor(status) {
    //   if(status == 'on_hold') return "warning"
    //   if(status == 'activo') return "success"
    //   if(status == 'inactivo') return "danger"
    //   return "primary"
    // },
    getStatusColor(status) {
      if(status == '1') return "success"
      if(status == '0') return "danger"
      return "primary"
    },
    getStatusText(status) {
      if(status == '1') return "activo"
      if(status == '0') return "inactivo"
      return "primary"
    },
    getPopularityColor(num) {
      if(num > 90) return "success"
      if(num >70) return "primary"
      if(num >= 50) return "warning"
      if(num < 50) return "danger"
      return "primary"
    },
    toggleDataSidebar(val=false) {
      this.addNewDataSidebar = val
    },
    showNotify(val = false) {
      if(val)
        this.showAddSuccess()
      else
        this.showError(val)
    },
    showError(msg) {
      this.$vs.notify({
        color: 'danger',
        title: 'Error',
        text: msg || 'No se pudo completar el proceso.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      })
    },
    showAddSuccess() {
      this.$vs.notify({
        color: 'success',
        title: 'Producto',
        text: 'Se han guardado los cambios.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      })
    }
  },
  created() {
    if(!moduleDataList.isRegistered) {
      this.$store.registerModule('dataList', moduleDataList)
      moduleDataList.isRegistered = true
    }
    if(!moduleDataListCategories.isRegistered) {
      this.$store.registerModule('dataListCategories', moduleDataListCategories)
      moduleDataListCategories.isRegistered = true
    }

    this.$store.dispatch("dataList/getProducts", this.$store.state.AppActiveUser.institution.id)
      .then(() => {
        this.products = this.$store.state.dataList.products
      })
      .catch(error => {console.log(error)})

  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;


      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 10px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
            &.img-container {
              // width: 1rem;
              // background: #fff;

              span {
                display: flex;
                justify-content: flex-start;
              }

              .product-img {
                height: 110px;
              }
            }
            .product-stock-min {
              max-width: 6rem;
              text-align: center;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
