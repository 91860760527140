export default {
    ADD_ITEM(state, item) {
      state.ingredients.unshift(item)
    },
    SET_INGREDIENTS(state, ingredients) {
      state.ingredients = ingredients
    },
    // SET_LABELS(state, labels) {
    //   state.eventLabels = labels
    // },
    UPDATE_INGREDIENT(state, ingredient) {
        const ingredientIndex = state.ingredients.findIndex((p) => p.id == ingredient.id)
        Object.assign(state.ingredients[ingredientIndex], ingredient)
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.ingredients.findIndex((p) => p.id == itemId)
        state.ingredients.splice(ItemIndex, 1)
    },
  }