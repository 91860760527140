export default {
  // getItem: state => (productId) => state.products.find((product) => product.id == productId),
  getProductsStatistics: (state, getters, rootState, rootGetters) => {
    const categories = rootGetters["dataListCategories/getCategories"];
    // Devolver {id_category: 1, products: 20, percentage: 12}
    let cats = categories.map(c => {
      let qty = state.products.filter(p => p.id_category == c.id).length;
      return {category: c.name, products: qty, percentage: (qty / state.products.length * 100).toFixed(2)};
    });

    return cats.filter(c => c.products > 0);
  }
}
